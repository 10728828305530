import ErrorPage from "next/error"
import { getPageData, getFieldPageData, fetchAPI, getGlobalData } from "/utils/api"
import Sections from "/components/sections"
import Seo from "/components/elements/seo"
import { useRouter } from "next/router"
import Layout from "/components/layout"
import { getLocalizedPaths } from "/utils/localize"
import HeroBanner from "../components/sections/hero-banner"
import Services from "../components/sections/services"
import ProjectsHome from "../components/sections/projects-home"
import EventsHome from "../components/sections/events-home"
import NewsHome from "../components/sections/news-home"
import { getGradientColors } from "../utils/colors"
import ImageGrid from "../components/sections/image-grid"

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const DynamicPage = ({ sections, metadata, preview, global, pageContext }) => {
  const router = useRouter()

  // Check if the required data was provided
  // if (!router.isFallback && !sections?.length) {
  //   return <ErrorPage statusCode={404} />
  // }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>
  }

  return (
    <Layout global={global} pageContext={pageContext} key={router.asPath}>
      {/* Add meta tags for SEO*/}
      <Seo metadata={metadata} />
      {/* Display content sections */}
      {pageContext.inicialBanner ? (<HeroBanner data={pageContext.inicialBanner} pageContext={pageContext}/>) : ""}
      {pageContext.services ? (<Services data={pageContext.services} pageContext={pageContext}/>) : ""}
      {pageContext.projectSection && pageContext.projects.length ? (<ProjectsHome data={pageContext.projectSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.eventSection && pageContext.events.length ? (<EventsHome data={pageContext.eventSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.newsSection && pageContext.articles.length ? (<NewsHome data={pageContext.newsSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      <Sections sections={sections} preview={preview} fields={global.data.fields} pageContext={pageContext} />
      {/* <ImageGrid /> */}
    </Layout>
  )
}

// export async function getStaticPaths(context) {
//   // Get all pages from Strapi
//   // console.log(context)
//   const allPages = context.locales.map(async (locale) => {
//     const localePages = await fetchAPI(`/pages?_locale=${locale}`)
//     return localePages
//   })

//   const pages = await (await Promise.all(allPages)).flat()
//   let paths = pages[0].data.map((page) => {
//     // Decompose the slug that was saved in Strapi
//     const slugArray = !page.slug ? false : page.slug.split("/")
//     return {
//       params: { slug: slugArray },
//       // Specify the locale to render
//       locale: page.locale,
//     }
//   })


//   const allFields = context.locales.map(async (locale) => {
//     const localeFields = await fetchAPI(`/fields?_locale=${locale}`)
//     return localeFields
//   })

//   const fields = await (await Promise.all(allFields)).flat()

//   const fieldPaths = fields[0].data.map((field) => {
//     // Decompose the slug that was saved in Strapi
//     const slugArray = !field.slug ? false : field.slug.split("/")

//     return {
//       params: { slug: slugArray },
//       // Specify the locale to render
//       locale: field.locale,
//     }
//   })

//   paths = paths.concat(fieldPaths)
//   // paths.map((path)=>{
//   //   console.log(path.params.slug);
//   // })

//   return { paths, fallback: 'blocking' }
// }

// export async function getStaticProps(context) {
//   console.log("render slug")
//   // console.log("context", context);
//   const { params, locale, locales, defaultLocale, preview = null } = context
//   // console.log("params", params);

//   const globalLocale = await getGlobalData(locale)

//   const fields = globalLocale.data.fields;

//   let pageData = null;
//   // avoid creating pages just for the fields so this is a fix to use a direct canonical url for seo
//   if (params.slug && fields.filter(function(e) {
//     // console.log(e);
//     return e.slug === params.slug[0]; 
//   }).length > 0) {
//       // console.log("staticProps", fields);
//       // Fetch pages. Include drafts if preview mode is on
//       pageData = await getFieldPageData(
//         { slug: !params.slug ? [""] : params.slug },
//         locale,
//         preview
//       )
    
//   }else{
//     // Fetch pages. Include drafts if preview mode is on
//     pageData = await getPageData(
//       { slug: !params.slug ? [""] : params.slug },
//       locale,
//       preview
//     )
//   }


//   if (pageData == null) {
//     console.log("pageNULL")
//     // Giving the page no props will trigger a 404 page
//     return { props: {} }
//   }

//   // We have the required page data, pass it to the page component
//   const { contentSections, metadata, localizations, slug, color1, color2, colorFooter } = pageData
//   // console.log("############# PAGE DATA ###############", pageData)

//   const pageContext = {
//     locale: pageData.locale,
//     locales,
//     defaultLocale,
//     slug,
//     localizations,
//     color1,
//     color2,
//     colorFooter,
//     projects: pageData.projects ? pageData.projects : null,
//     articles: pageData.articles ? pageData.articles : null,
//     events: pageData.events ? pageData.events : null,
//   }

//   const localizedPaths = getLocalizedPaths(pageContext)

//   return {
//     props: {
//       preview,
//       sections: contentSections,
//       metadata,
//       global: globalLocale,
//       pageContext: {
//         ...pageContext,
//         localizedPaths,
//       },
//     },
//     revalidate: 60,
//   }
// }

export async function getServerSideProps(context) {
  // console.log("render slug")
  // console.log("context", context);
  const { params, locale, locales, defaultLocale, preview = null } = context
  // console.log("\n\n\n\n\n\n\n\n\n\n\n PARAMS", params, "\n\n\n\n\n\n\n\n\n\n\n\n\n");

  const globalLocale = await getGlobalData(locale)

  const fields = globalLocale.data.fields;
  // console.log(fields)

  let pageData = null;
  // avoid creating pages just for the fields so this is a fix to use a direct canonical url for seo
  if (params.slug && fields.filter(function(e) {
    // console.log(e);
    return e.slug === params.slug; 
  }).length > 0) {
      // console.log("staticProps", fields);
      // Fetch pages. Include drafts if preview mode is on
      pageData = await getFieldPageData(
        { slug: !params.slug ? [""] : [params.slug] },
        locale,
        preview
      )
  }else{
    // Fetch pages. Include drafts if preview mode is on
    pageData = await getPageData(
      { slug: !params.slug ? [""] : [params.slug] },
      locale,
      preview
    )
  }


  if (pageData == null) {
    // console.log("pageNULL")
    // Giving the page no props will trigger a 404 page
    return { props: {} }
  }

  // We have the required page data, pass it to the page component
  const { contentSections, seo, localizations, slug, color1, color2, colorFooter } = pageData
  // console.log("############# PAGE DATA ###############", pageData)
  const colors = getGradientColors()["base"];
  const pageContext = {
    locale: pageData.locale,
    locales,
    defaultLocale,
    slug,
    localizations,
    color1: colors.color1,
    color2: colors.color2,
    colorFooter: colors.colorFooter,
    inicialBanner: pageData.inicialBanner ?? null,
    services: pageData.services ?? null,
    projectSection: pageData.projectSection ?? null,
    eventSection: pageData.eventSection ?? null,
    newsSection: pageData.newsSection ?? null,
    projects: pageData.projects ? pageData.projects : null,
    articles: pageData.articles ? pageData.articles : null,
    events: pageData.events ? pageData.events : null,
  }

  const localizedPaths = getLocalizedPaths(pageContext)

  return {
    props: {
      preview,
      sections: contentSections,
      metadata: seo,
      global: globalLocale,
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
    }
  }
}

export default DynamicPage
